
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('dae_config.crop_wise_agricultural_material_mapping') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="4" sm="12" md="4">
                        <b-form-group
                          class="row"
                          label-for="crop_id"
                          label-cols-sm="12"
                          >
                          <template v-slot:label>
                          {{ $t('dae_config.crop_name') }}
                          </template>
                          <b-form-select
                          plain
                          v-model="search.crop_id"
                          :options="cropList"
                          id="crop_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                       </b-form-group>
                    </b-col>
                    <b-col lg="4" sm="4">
                            <b-form-group
                                class="row"
                                label-for="mat_type_id"
                                label-cols-sm="12"
                                >
                                <template v-slot:label>
                                {{ $t('dae_config.agriculture_material_type_name') }}
                                </template>
                                <b-form-select
                                plain
                                v-model="search.mat_type_id"
                                :options="agMaterialTypeList"
                                id="index"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                    </b-col>
                    <b-col lg="4" sm="4">
                        <b-form-group
                            class="row"
                            label-for="mat_id"
                            label-cols-sm="12"
                            >
                            <template v-slot:label>
                            {{ $t('dae_config.agriculture_material_name') }}
                            </template>
                            <b-form-select
                            plain
                            v-model="search.mat_id"
                            :options="agMaterialList"
                            id="mat_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="6" sm="12" md="6">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('dae_config.crop_wise_agricultural_material_mapping') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                            {{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table  :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                            <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                                                <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                            </b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { cropWiseAgMaMapList, cropWiseAgMaMapStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        crop_id: 0,
        mat_type_id: 0,
        mat_id: 0
      },
      rows: [],
      item: ''
    }
  },
  mounted () {
  },
  computed: {
      formTitle () {
        return (this.editItemId === 0) ? this.$t('dae_config.crop_wise_agricultural_material_mapping') + ' ' + this.$t('globalTrans.entry') : this.$t('dae_config.crop_wise_agricultural_material_mapping') + ' ' + this.$t('globalTrans.modify')
      },
      columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('dae_config.crop_name'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'crop.crop_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'crop.crop_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    cropList: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.cropList
      return objectData.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    agMaterialTypeList: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.agMaterialTypeList
      return objectData.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    agMaterialList: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.agMaterialList
      return objectData.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, cropWiseAgMaMapStatus, item, 'incentive_grant', 'cropWiseAgMaMapList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, cropWiseAgMaMapList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.assingDes(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    assingDes (data) {
      return data.map(item => {
          const details = item.details.map(itm => {
            return Object.assign(itm, {
              description: this.loadMatDescList(itm.mat_id)
            })
          })
          return Object.assign(item, {
            details: details
          })
      })
    },
    loadMatDescList (matId) {
        const objectData = this.$store.state.incentiveGrant.commonObj.agMaterialList.find(item => item.value === matId)
        return (this.$i18n.locale === 'bn') ? objectData.description_bn : objectData.description
    }
  }
}
</script>
