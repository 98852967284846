<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                            <b-row>
                                                <b-col lg="4" sm="4">
                                                    <ValidationProvider name="Crop Name" vid="crop_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-for="crop_id"
                                                            label-cols-sm="12"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.crop_name') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                            plain
                                                            v-model="formData.crop_id"
                                                            :options="cropList"
                                                            id="crop_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <b-row v-for="(item, index) in formData.details" :key="index">
                                                <b-col lg="3" sm="3">
                                                    <ValidationProvider name="Material Type" vid="mat_type_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-for="mat_type_id"
                                                            label-cols-sm="12"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.agriculture_material_type_name') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                            plain
                                                            v-model="item.mat_type_id"
                                                            :options="agMaterialTypeList"
                                                            id="index"
                                                            @change="loadMatNameList($event, index)"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="3" sm="3">
                                                    <ValidationProvider name="Material name" vid="mat_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-for="mat_id"
                                                            label-cols-sm="12"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('dae_config.agriculture_material_name') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                            plain
                                                            v-model="item.mat_id"
                                                            :options="item.agMaterialList"
                                                            id="mat_id"
                                                            @input="loadMatDescList($event, index)"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xl="3" lg="3" sm="8">
                                                    <ValidationProvider name="Description (En)" vid="description" rules="required">
                                                        <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="description"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.description') }}
                                                        </template>
                                                        <b-form-input
                                                            ml="3"
                                                            id="description"
                                                            readonly
                                                            v-model="item.description"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xl="3" lg="3" sm="8">
                                                    <label></label>
                                                    <div class="text-left mt-2">
                                                        <b-button v-if="index === 0" @click="addNew" type="button" variant="primary" class="mr-2">+</b-button>
                                                        &nbsp;
                                                        <b-button v-if="index != 0" @click="remove(index)" type="button" variant="danger" class="mr-2">X</b-button>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                            <div class="row mt-4">
                                                <div class="col-md-12">
                                                    <div class="col text-right">
                                                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                        &nbsp;
                                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { cropWiseAgMaMapStore, cropWiseAgMaMapUpdate } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getformDataData()
            this.formData = tmp
        }
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            formData: {
                crop_id: 0,
                details: [{
                    mat_type_id: 0,
                    mat_id: 0,
                    description: '',
                    agMaterialList: []
                }]
            },
            singleRow: {
                mat_type_id: 0,
                mat_id: 0,
                description: '',
                agMaterialList: []
            }
        }
    },
    mounted () {
        core.index()
    },
    computed: {
        cropList: function () {
            const objectData = this.$store.state.incentiveGrant.commonObj.cropList.filter(item => item.status === 1)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        agMaterialTypeList: function () {
            const objectData = this.$store.state.incentiveGrant.commonObj.agMaterialTypeList.filter(item => item.status === 1)
            return objectData.map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        }
    },
    methods: {
        addNew () {
            this.formData.details.push(this.singleRow)
        },
        remove (index) {
            this.formData.details.splice(index, 1)
        },
        getformDataData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            const data = JSON.parse(JSON.stringify(tmpData))
            data.details.map(item => {
                item.agMaterialList = this.loadEditData()
            })
            return data
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${cropWiseAgMaMapUpdate}/${this.id}`, this.formData)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, cropWiseAgMaMapStore, this.formData)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false

            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        loadMatNameList (typeId = 0, index) {
            const objectData = this.$store.state.incentiveGrant.commonObj.agMaterialList.filter(item => item.material_type_id === typeId && item.status === 1)
            this.formData.details[index].agMaterialList = objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
            const tmp = this.formData.details
            this.formData.details = []
            this.formData.details = tmp
        },
        loadMatDescList (matId, index) {
            const objectData = this.$store.state.incentiveGrant.commonObj.agMaterialList.find(item => item.value === matId)
            this.formData.details[index].description = (this.$i18n.locale === 'bn') ? objectData.description_bn : objectData.description
            const tmp = this.formData.details
            this.formData.details = []
            this.formData.details = tmp
        },
        loadEditData () {
            const objectData = this.$store.state.incentiveGrant.commonObj.agMaterialList.filter(item => item.status === 1)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        }
    }
}
</script>
